import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import { StaticImage } from 'gatsby-plugin-image'

const EspaceMedias = ({ location }) => {
  const metaTitle = 'Espace médias & pros | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Espace réservé aux médias, comprenant formulaire de demande d’accréditation, revue de presse, archives photos, pochette de presse et communiqués'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, médias, kit média, entrevues, journalistes'

  return (
    <Wolflayout mobileTitle={'Espace-médias'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} href={location.href} />
      <Banner
        title="Espace-médias &"
        titleLine2= "pros"
      />
      <section className="my-0 mx-auto p-5 md:p-24 max-w-5xl text-white">
        <section className="flex flex-col md:flex-row justify-between pb-5 md:pb-12 box-border">
          <section className="md:w-5/12 max-w-full block box-border">
            <div className="font-body font-light box-border mt-16 md:mt-0">
              <h2 className="text-xl font-body font-black leading-6 mt-0 box-border mb-5">
                Accréditation 2024
              </h2>
              <p className="text-2xl leading-7 mt-0 mb-3">
                Tu peux faire ta demande en remplissant l'un des
                formulaires ci-joints:
              </p>
              <br />
              <p className="mt-0 mb-3 box-border uppercase leading-5 text-lg">
                DATE LIMITE : 01 JUILLET 2024
              </p>
              <p className="leading-5 text-lg mb-3 box-border">
                {/* <a
                  className="underline"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfTnwFUbWs3Ajo19Q7mI_2UoDe5uk4yrxAIp5lWcU2W0qewdQ/viewform"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Formulaire média
                </a> */}
              </p>
              <p className="leading-5 text-lg mb-3 box-border">
                {/* <a
                  className="underline"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScnQeXv1nkUrJWR5lq69Txm1cLleBGPKEfPK9UJrq3gQsDbyg/closedform"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Formulaire pro
                </a> */}
              </p>
            </div>
          </section>
          <section className="md:w-5/12 max-w-full block box-border">
            <div className=" mt-12 md:mt-0 font-body font-light box-border">
              <h2 className="text-xl font-body font-black leading-6 mt-0 box-border mb-5">
                Photos du Festif!
              </h2>
              <p className="leading-5 text-lg mb-3 box-border">
              <a
                  href="/photos-videos"
                  className="underline"
                  target={'_blank'}
                  rel="noreferrer">
                    Consulter
                    </a>
              </p>
            </div>
            <div className="mt-12 font-body font-light box-border">
              <h2 className="text-xl font-body font-black leading-6 mt-0 box-border mb-5">
                Pochette de presse 2024
              </h2>
              <p className="leading-5 text-lg mb-3 box-border">
                <a
                  href="https://drive.google.com/drive/folders/1bEyLo9hWNQoWQsn3AuFi9dACi4EkHkjb?usp=drive_link"
                  className="underline"
                  target={'_blank'}
                  rel="noreferrer">
                  Accéder au dossier
                </a>
              </p>
            </div>
          </section>
        </section>

        <div className='text-center'>
          <StaticImage className="max-w-[250px] max-h-[125px]" imgClassName="!object-contain object-left invert" src="../images/partenaires/centrale_alternative.png" alt="Logo Central Alternative" placeholder="none"/>
        </div>
      </section>
    </Wolflayout>
  )
}

export default EspaceMedias
